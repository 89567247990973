import React, { createContext, useState } from "react";
import moment from 'moment';

const FiltrosContext = createContext({});

export const FiltrosProvider = ({ children }) => {
    const [filtros, setFiltros] = useState({});
    const [aguarde, setAguarde] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [filial, setFilial] = useState('');
    const [dataInicial, setDataInicial] = useState(moment().format('yyyy-MM-DD'));
    const [dataFinal, setDataFinal] = useState(moment().format('yyyy-MM-DD'));


    return (
        <FiltrosContext.Provider value={{
            filial, setFilial,
            filtros, setFiltros,
            aguarde, setAguarde,
            errMsg, setErrMsg,
            dataInicial, setDataInicial,
            dataFinal, setDataFinal
        }}>
            {children}
        </FiltrosContext.Provider>
    )
}

export default FiltrosContext;