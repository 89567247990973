// import { Container } from "reactstrap";
import Cards from "./Cards";
import Filtros from "./Filtros";
import "../index.css"
import CaixaDeSugestoes from "./CaixaDeSugestoes";
import DataSinc from "./DataSinc";

const Home = () => {

    return (

        <>
            <div className="home" >
                <Filtros />
                <Cards />
                <DataSinc />
                <CaixaDeSugestoes />
            </div>
        </>
    )
}

export default Home
