import React, { useContext, useEffect, useState } from 'react';
import gestorNav from '../resources/gestorNav.png'
import logoTPBranco from '../resources/logoTPBranco.png'
import logoTGMBranco from '../resources/logoTGMBranco.png';
import moment from 'moment';

import { Container, Form } from 'react-bootstrap';
import FiltrosContext from '../context/FiltrosController';
import useAuth from '../hooks/useAuth';


function NavBar() {
    moment.locale('en');
    
    const { filial, setFilial, setFiltros, dataInicial, dataFinal } = useContext(FiltrosContext);
    const { auth } = useAuth();
    const { filialList } = auth;
    const [moduloManipulacao, setModuloManipulacao] = useState(false);

    const updateFiltros = (filialAtual) => {
        if (!filialAtual) return;
        const selectedFilial = filialList.find(f => f.idFilial === parseInt(filialAtual));
        const moduloManipulacao = selectedFilial ? selectedFilial.moduloManipulacao : false;

        setModuloManipulacao(moduloManipulacao);
        setFiltros({ filial: filialAtual, dataInicial, dataFinal, moduloManipulacao });
    };

    const submit = (e) => {
        e.preventDefault();
        updateFiltros(filial);
    };

    useEffect(() => {
        if (filial && dataInicial && dataFinal) {
            updateFiltros(filial);
        }
    }, [filial, dataInicial, dataFinal]);

    useEffect(() => {
        if (filialList.length > 1) {
            const initialFilial = filialList[1].idFilial.toString();
            setFilial(initialFilial);
            updateFiltros(initialFilial);
        }
    }, [filialList]);

    let optionItems = filialList.map((f, i) => (
        <option key={i} value={f.idFilial.toString()}>
            {`${f.idFilial !== 9999 ? f.idFilial : ""} ${f.nomeAbreviado}`}
        </option>
    ));

    if (optionItems.length === 2) {
        optionItems = filialList
            .filter(f => f.idFilial !== 9999)
            .map((f, i) => (
                <option key={i} value={f.idFilial.toString()}>
                    {`${f.idFilial !== 9999 ? f.idFilial : ""} ${f.nomeAbreviado}`}
                </option>
            ));
    }

    return (
        <>
            <nav className="w-full flex relative justify-between items-center mx-auto p-2 h-15" style={{ backgroundColor: '#00C7D4' }}  >
                <div>
                    <img src={logoTGMBranco} style={{ display: 'flex', justifyContent: 'left', marginLeft: '0.5rem', maxHeight: '100px', maxWidth: '128px' }} />
                </div>
                <div className="hidden sm:block flex-shrink flex-grow-0 justify-start px-2">
                    <div className="inline-block">
                        <div className="inline-flex items-center max-w-full">
                        </div>
                    </div>
                </div>
                <div>
                    <Form onSubmit={(e) => submit(e)} className="mb-1">
                        <div>
                            <div>
                                <div className="flex-initial">
                                    <Form.Group className="flex justify-end align-baseline mt-2 text-sm text relative" controlId="filial" style={{ columnGap: '1rem' }}>
                                        <div style={{ fontSize: '0.8rem', marginBottom: '1px', color: "#FFFFFF", fontWeight: '600', fontFamily: 'Roboto', minWidth: '55px' }}>
                                            FARMÁCIA
                                        </div>
                                        <select
                                            style={{ textDecoration: 'underline', marginBottom: '10px', color: 'white', fontWeight: '600', fontSize: '12px', fontFamily: 'Inter', maxWidth: '150px' }}
                                            value={filial}
                                            onChange={(e) => {
                                                const selectedFilial = e.target.value;
                                                setFilial(selectedFilial);
                                                updateFiltros(selectedFilial);
                                            }}
                                            size="sm"
                                        >
                                            {optionItems}

                                        </select>

                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </nav>

        </>

    )
}

export default NavBar