import { Card } from "reactstrap";
import '../assets/css/style.css'



const Icones = {
  'Venda': { icon: "fa-solid fa-wallet", cor: "bg-gradient-success" },
  'Entrada': { icon: "fa-solid fa-dolly", cor: "bg-gradient-teal" },
  'Atendimento': { icon: "fa-solid fa-users", cor: "bg-gradient-orange" },
  'Desconto': { icon: "fas fa-percent", cor: "bg-gradient-info" },
  'DevCliente': { icon: "fas fa-people-carry", cor: "bg-gradient-danger" },
  'DevFornecedor': { icon: "fa-solid fa-truck", cor: "bg-gradient-info" },
  'Cancelamento': { icon: "fa-solid fa-ban", cor: "bg-gradient-red" },
  'CMV': { icon: "fa-solid fa-chart-column", cor: "bg-gradient-red" },
  'TicketMedio': { icon: "fa-solid fa-receipt", cor: "bg-gradient-red" },
  'MediaItensVenda': { icon: "fa-solid fa-chart-pie", cor: "bg-gradient-info" },
  'MargemBruta': { icon: "fa-solid fa-chart-line", cor: "bg-gradient-info" },
  'IndiceCompraVenda': { icon: "fa-solid fa-arrows-up-down", cor: "bg-gradient-info" },
  'TotalPedidos': { icon: "fa-solid fa-pen", cor: "bg-gradient-info" },
  'OrcamentosEfetuados': { icon: "fa-solid fa-calculator", cor: "bg-gradient-info" },
  'OrcamentosConvertidos': { icon: "fa-solid fa-repeat", cor: "bg-gradient-info" },
  'TicketMedioManip': { icon: "fa-solid fa-receipt", cor: "bg-gradient-info" },
}

const TPCard = (props) => {
  const { key, titulo, label, valor, quantidade, css, percentual, qtd } = props.infoCard;

  const icon = () => {
    switch (titulo) {
      case 'VENDA':
        return Icones.Venda;
      case 'ENTRADA':
        return Icones.Entrada;
      case 'DESCONTO':
        return Icones.Desconto;
      case 'CANCELAMENTO':
        return Icones.Cancelamento;
      case 'DEVOLUCAOCLIENTE':
        return Icones.DevCliente;
      case 'ATENDIMENTO':
        return Icones.Atendimento;
      case 'DEVOLUCAOFORNECEDOR':
        return Icones.DevFornecedor;
      case 'CMV':
        return Icones.CMV;
      case 'TICKETMEDIO':
        return Icones.TicketMedio;
      case 'MEDIAITENSPORVENDA':
        return Icones.MediaItensVenda;
      case 'MARGEMBRUTA':
        return Icones.MargemBruta;
      case 'INDICECOMPRAVENDA':
        return Icones.IndiceCompraVenda;
      case 'TOTALPEDIDOS':
        return Icones.TotalPedidos;
      case 'ORCAMENTOSEFETUADOS':
        return Icones.OrcamentosEfetuados;
      case 'ORCAMENTOSCONVERTIDOS':
        return Icones.OrcamentosConvertidos;
      case 'TICKETMEDIOMANIP':
        return Icones.TicketMedioManip;
      default: return Icones.MargemBruta;

    }
  }

  const Titulo = () => {
    switch (titulo) {
      case 'VENDA':
        return "Venda Líquida";
      case 'DEVOLUCAOCLIENTE':
        return "Devolução Cliente";
      case 'DEVOLUCAOFORNECEDOR':
        return "Devolução Fornecedor";
      case 'MEDIAITENSPORVENDA':
        return "Média Itens x Venda";
      case 'INDICECOMPRAVENDA':
        return "Índice Compra x Venda";
      case 'TICKETMEDIO':
        return "Ticket Médio";
      case 'MARGEMBRUTA':
        return "Margem Bruta";
      case 'DESCONTO':
        return "Média Desconto";
      case 'CMV':
        return "CMV";
      case 'ATENDIMENTO':
        return "Atendimentos não convertidos";
      case 'CANCELAMENTO':
        return "Cancelamento";
      case 'ENTRADA':
        return "Entrada de Mercadoria";
      case 'TOTALPEDIDOS':
        return "Total de Pedidos"
      case 'ORCAMENTOSEFETUADOS':
        return "Orçamentos Efetuados"
      case 'ORCAMENTOSCONVERTIDOS':
        return 'Orçamentos Convertidos'
      case 'TICKETMEDIOMANIP':
        return 'Ticket Médio'


      default: return titulo;
    }
  }
  const getValor = (number) => {
    return titulo === 'ATENDIMENTO'
      ? number : titulo === "INDICECOMPRAVENDA"
        ? (Math.round(number).toLocaleString('pt-BR'))
        : (Math.round(number * 100) / 100).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  const icone = icon();

  return (
    <div style={{ minWidth:`${css}` }} className={`row row-cols-1 row-cols-md-1 g-3 ml-1 mt-0 shadow-lg pb-1 pt-0 shadow-black `} >

      <Card key={key}
        style={{ marginTop: `1px`, minHeight: '8.5rem' }}>
        
        <div className={` ${icone?.icon} text-white rounded-md d-flex shadow align-items-center justify-center w-6 h-6 mt-2 `} style={{fontSize: '0.9rem', backgroundColor:'#00C7D4' }}> </div>
        <div className="TituloDiv">{Titulo()}</div>

        <div className="ValorDiv">
          <span className={`font-weight-bold ${css && `text-${css}`} `}>
            {label} {''} {valor && 'R$' + getValor(valor)}
            {!valor && percentual && percentual > 0 ? `${getValor(percentual)}%` : qtd}
          </span>
        </div>
        <div className="DetalhesDiv">
          <span className={`font-weight-bold mb-0 ${css && `text-${css}`} `}>
            {quantidade && quantidade > 0 ? <> {`Qtd.: ${quantidade}`} </> : <></>}
            {valor && percentual > 0 ? `${getValor(percentual)} %` : <></>}
          </span>
        </div>
      </Card>
    </div>

  );
};
export { Icones };
export default TPCard;