import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import FiltrosContext from '../context/FiltrosController';
import useAuth from '../hooks/useAuth';

const Filtros = () => {
    moment.locale('en');
    const { filial, setFiltros, dataInicial, setDataInicial, dataFinal, setDataFinal } = useContext(FiltrosContext);
    const { auth } = useAuth();
    const { filialList } = auth;
    const [moduloManipulacao, setModuloManipulacao] = useState(false);

    const updateFiltros = (filialAtual) => {
        if (!filialAtual) return;
        const selectedFilial = filialList.find(f => f.idFilial === parseInt(filialAtual));
        const moduloManipulacao = selectedFilial ? selectedFilial.moduloManipulacao : false;

        const temManipulacao = filialList.some(f => f.moduloManipulacao === true);
        if (temManipulacao) {
            const filialTodas = filialList.find(f => f.idFilial === 9999);
            if (filialTodas) {
                filialTodas.moduloManipulacao = true;
            }
        }

        setModuloManipulacao(moduloManipulacao);
        setFiltros({ filial: filialAtual, dataInicial, dataFinal, moduloManipulacao });
    };
    const submit = (e) => {
        e.preventDefault();
        updateFiltros(filial);
    };

    useEffect(() => {
        if (filial && dataInicial && dataFinal) {
            updateFiltros(filial);
        }
    }, [filial, dataInicial, dataFinal, filialList]);

    return (
        <Container className="classFiltros">
            <Form onSubmit={(e) => submit(e)} className="mb-1">
                <div>
                    <div>
                        <div>
                        </div>
                    </div>
                    <div className='containerFiltro' style={{ maxWidth: '320px', fontSize: '0.7rem', marginTop: '1.8rem', marginBottom: '1px', color: "#FFFFFF", fontWeight: '600', fontFamily: 'Roboto', alignItems: 'center' }}>
                        <div className='ml-1'>PERÍODO</div>
                        <input
                            type="date"
                            size="sm"
                            onChange={(e) => setDataInicial(e.target.value)}
                            value={dataInicial}
                            name="Data Inicial"
                            style={{ fontSize: '0.7rem', backgroundColor: 'transparent', border: 'none', paddingLeft: '0px', marginLeft: '1px' }} />
                        <input
                            type="date"
                            size="sm"
                            onChange={(e) => setDataFinal(e.target.value)}
                            value={dataFinal}
                            name="Data Final"
                            style={{ fontSize: '0.7rem', backgroundColor: 'transparent', border: 'none', paddingLeft: '0px' }} />
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default Filtros;
