import React, { useContext, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import TPCard from './Card';
import FiltrosContext from '../context/FiltrosController';
import AuthContext from '../context/AuthProvider';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import '../index.css';
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { styled } from '@mui/material/styles';

function Cards() {
    const { logout } = useContext(AuthContext);
    const { filtros, setAguarde, setErrMsg } = useContext(FiltrosContext);
    const { auth } = useAuth();

    const [retornoApi, setRetornoApi] = useState([]);
    const [venda, setVenda] = useState(0.0);
    const [custo, setCusto] = useState(0.0);
    const [desconto, setDesconto] = useState(0.0);
    const [activeMode, setActiveMode] = useState('1');
    const [hasManipulacaoData, setHasManipulacaoData] = useState(false);
    const [orcamentosEfetuados, setOrcamentosEfetuados] = useState(0);
    const [orcamentosConvertidos, setOrcamentosConvertidos] = useState(0);
    const { filial, dataInicial, dataFinal, moduloManipulacao } = filtros;
    const { accessToken, acessoFiliais, identificadorAplicativoWeb } = auth;
    const isManipulacao = ["ORCAMENTOSEFETUADOS", "TOTALPEDIDOS", "ORCAMENTOSCONVERTIDOS", "TICKETMEDIOMANIP"];

    const handleModeChange = (event, novoValor) => {
        setActiveMode(novoValor);
    };


    const AntTabs = styled(Tabs)({
        backgroundColor: '#00c7d4',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.5rem',
        border: 'none',
        '& .MuiTabs-indicator': {
            backgroundColor: '#ffffff',
        },
    });

    const StyledTab = styled(Tab)({
        backgroundColor: '#00c7d4',
        textTransform: 'none',
        fontSize: '1rem',
        border: 'none',
        flexGrow: 1,
        '&.Mui-selected': {
            color: '#f0eeee',
        },
    });


    const consultarDadosApi = async () => {
        try {
            setAguarde(true);

            const response = await axios.post(
                `/valores/list/${identificadorAplicativoWeb}`,
                JSON.stringify({ filial, dataInicial, dataFinal, acessoFiliais }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `tokenTools ${accessToken}`
                    }
                }
            );

            setRetornoApi(response?.data);
      

            response?.data.map((dadosCard) => {
                if (dadosCard.tipo === "VENDA") {
                    setVenda(dadosCard.valor);
                    setCusto(dadosCard.custo);
                }
                if (dadosCard.tipo === "DESCONTO") {
                    setDesconto(dadosCard.valor);
                }
                if (dadosCard.valor === "") {
                    setVenda('--');
                    setCusto('--');
                }
                if (dadosCard.tipo === "ORCAMENTOSEFETUADOS") {
                    setOrcamentosEfetuados(dadosCard.quantidade);
                }
                if (dadosCard.tipo === "ORCAMENTOSCONVERTIDOS") {
                    setOrcamentosConvertidos(dadosCard.quantidade);
                }
            });

        } catch (err) {
            console.log(err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Favor informar um usuário');
            } else if (err.response?.status === 401) {
                logout();
            } else {
                setErrMsg('Sem Acesso ao servidor.');
            }
        } finally {
            setAguarde(false);
        }
    };

    useEffect(() => {
        consultarDadosApi();
    }, [filtros]);

    useEffect(() => {
        setHasManipulacaoData(moduloManipulacao);
        setActiveMode('drogaria');
    }, [moduloManipulacao, filial]);

    const getCardsOrdenados = () => {
        const cardsOrdenados = [];

        const totalPedidos = retornoApi.find(dadosCard => dadosCard.tipo === "TOTALPEDIDOS");
        const ticketMedioManip = retornoApi.find(dadosCard => dadosCard.tipo === "TICKETMEDIOMANIP");
        const orcamentosEfetuados = retornoApi.find(dadosCard => dadosCard.tipo === "ORCAMENTOSEFETUADOS");
        const orcamentosConvertidos = retornoApi.find(dadosCard => dadosCard.tipo === "ORCAMENTOSCONVERTIDOS");

        if (totalPedidos) {
            cardsOrdenados.push(
                <TPCard key={totalPedidos.tipo} infoCard={{ key: totalPedidos.tipo, titulo: totalPedidos.tipo, qtd: totalPedidos.quantidade }} />
            );
        }

        if (ticketMedioManip) {
            cardsOrdenados.push(
                <TPCard key={ticketMedioManip.tipo} infoCard={{ key: ticketMedioManip.tipo, titulo: ticketMedioManip.tipo, valor: ticketMedioManip.valor }} />
            );
        }

        if (orcamentosEfetuados) {
            cardsOrdenados.push(
                <TPCard key={orcamentosEfetuados.tipo} infoCard={{ key: orcamentosEfetuados.tipo, titulo: orcamentosEfetuados.tipo, qtd: orcamentosEfetuados.quantidade }} />
            );
        }

        if (orcamentosConvertidos) {
            cardsOrdenados.push(
                <TPCard key={orcamentosConvertidos.tipo} infoCard={{ key: orcamentosConvertidos.tipo, titulo: orcamentosConvertidos.tipo, qtd: orcamentosConvertidos.quantidade }} />
            );
        }

        if (orcamentosEfetuados && orcamentosConvertidos) {
            const taxaConversao = (orcamentosConvertidos.quantidade / orcamentosEfetuados.quantidade) * 100;
            cardsOrdenados.push(
                <TPCard key="TAXACONVERSAOMANIP" infoCard={{ key: "TAXACONVERSAOMANIP", titulo: "Taxa Conversão", percentual: taxaConversao, qtd: 0, css: '98%' }} />
            );
        }
        return cardsOrdenados;
    };

    return (
        <Container>
            {hasManipulacaoData && (
                <div>
                    {/* #00c7d4 */}
                    <div>
                        <AntTabs
                            value={activeMode}
                            onChange={handleModeChange}

                        >
                            <StyledTab value="drogaria" label="Drogaria" />
                            <StyledTab value="manipulacao" label="Manipulação" />

                        </AntTabs>
                        <div>
                          
                        </div>
                    </div>

                </div>
            )}
  <Row xs="2" sm="2">
                                {activeMode === 'manipulacao' ? getCardsOrdenados() : (
                                    retornoApi.map((dadosCard, i) => {
                                        if (!isManipulacao.includes(dadosCard.tipo)) {
                                            if (dadosCard.tipo === "VENDA") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: venda - desconto, quantidade: dadosCard.quantidade }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "DESCONTO") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, percentual: venda > 0 ? (desconto * 100) / venda : 0, qtd: 0 }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "INDICECOMPRAVENDA") {
                                                let indice = venda > 0 ? (dadosCard.valor / (venda - desconto)) * 100 : 0;
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, percentual: indice, css: indice > 70 ? "danger" : "success", qtd: 0 }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "CMV") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: custo }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "CANCELAMENTO") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: dadosCard.valor, quantidade: dadosCard.quantidade }} />
                                                );
                                            }
                                            if (dadosCard.tipo === "ATENDIMENTO") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, qtd: dadosCard.quantidade }} />
                                                );
                                            }
                                            if (dadosCard.tipo === "MARGEMBRUTA") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, percentual: venda > 0 ? (1 - (custo / (venda - desconto))) * 100 : 0, qtd: 0 }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "MEDIAITENSPORVENDA") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: dadosCard.valor > 0 ? Math.round((dadosCard.quantidade / dadosCard.valor) * 100) / 100 : 0 }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "ENTRADA") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: dadosCard.valor > 0 ? Math.round((dadosCard.quantidade / dadosCard.valor) * 100 / 100) : 0 }} />
                                                );
                                            }

                                            if (dadosCard.tipo === "TICKETMEDIO") {
                                                return (
                                                    <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: dadosCard.valor > 0 ? Math.round(((venda - desconto) / dadosCard.quantidade) * 100) / 100 : 0 }} />
                                                );
                                            }

                                            return (
                                                <TPCard key={i} infoCard={{ key: i, titulo: dadosCard.tipo, valor: dadosCard.valor, quantidade: dadosCard.quantidade }} />
                                            );
                                        }
                                        

                                        return null;
                                    })
                                )}
                            </Row>
        </Container>
    );
}

export default Cards;
