import React from 'react'
import usePWA from 'react-pwa-install-prompt'
import PWAPrompt from 'react-ios-pwa-prompt'
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Navbar } from 'reactstrap'
import { ArrowDownCircleIcon, LockClosedIcon } from '@heroicons/react/20/solid'
import { Container } from 'react-bootstrap';
import mobileDownload from '../resources/mobileDownload.svg'
import logoTP from '../resources/logotp.png'
import mobileUser from '../resources/mobileUser.svg'
import NavBar from './NavBar';

function Install() {


  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { isInstallPromptSupported, promptInstall } = usePWA()

  const onClickInstall = async () => {
    const didInstall = await promptInstall()
    if (didInstall) {
      navigate(from, { replace: true });
    }
  }

  const renderButtonAndroid = () => {
    if (isInstallPromptSupported) {
      return <button 
      className="px-16 rounded-md bg-teal-400 text-white font-bold p-4 uppercase border-teal-500 border hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-400 focus:ring-offset-2"
      onClick={onClickInstall}>Instale agora</button>
    }
    return 
  }




  const renderButtonIOS = () => {
      return <PWAPrompt promptOnVisit={1}
              timesToShow={10}
              copyClosePrompt="Fechar"
              permanentlyHideOnDismiss={false}
              copyTitle="Aplicativo ToolsGestorMob"
              copyBody="Siga os passos abaixo para instalar nosso App"
              copyShareButtonLabel="1) Pressione o botão 'Compartilhar' na barra de menu abaixo."
              copyAddHomeButtonLabel="2) Pressione 'Adicionar à tela inicial' " />
  }

  return (
    <>   
    <Container>
     
    <div className="text-white mt-0 0 py-0 w-full h-full" style={{backgroundImage:`url('')`}}>
    <header className="flex md:justify-between p-4">
        <div className="flex align-items-center">
          <img className="sm:w-28 w-10 inline mr-3" src={logoTP}  alt="logo toolspharma"/>  
        </div>
       
    </header>
    
    <section className="px-2 lg:px-0 mt-16 sm:mt-0 flex w-full container mx-auto pb-16">
   
        <div className="w-full text-white flex justify-center  text-center md:text-left">
            <div >
                <h2 className="leading-none font-bold text-2xl xs:text-2x1 md:text-5xl lg:6x1 uppercase">App mobile  <span className="text-teal-400">ToolsGestorMob</span></h2>
                <div style= {{justifyContent: 'center' ,alignContent:'center', display: 'flex'}}>
                <img src={mobileUser} style={{maxHeight: '70%', maxWidth:'70%' }} alt="Mobile Phone" />
                </div>
                <p className="text-black  mb-10">Acompanhe informações atualizadas da sua farmácia em qualquer lugar e na palma da sua mão 
</p>         {renderButtonAndroid()}
          {renderButtonIOS()}
             
            </div>   
 
        </div>
    </section>
    
</div>
</Container>
 
</>


  )

}

export default Install