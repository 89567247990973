import { Fragment, useState, React } from 'react';
import useAuth from '../hooks/useAuth';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild, Input, Field } from '@headlessui/react';
import { LightBulbIcon } from '@heroicons/react/24/outline'
import axios from '../api/axios';
import ClipLoader from "react-spinners/ClipLoader";

function CaixaDeSugestoes() {
  const [open, setOpen] = useState(false)
  const [mensagem, setMensagem] = useState('');
  const { auth } = useAuth();
  const { identificadorAplicativoWeb, usuario, cnpj } = auth;
  const [carregando, setCarregando] = useState(false);

  function abrirCaixaDeSugestoes() {
    setOpen(true)
  }
  const handleChange = (event) => {
    setMensagem(event.target.value);
  };
  const enviarMensagem = async () => {
    setCarregando(true);
    try {
      await axios.post(`usuario/emailSugestao/${identificadorAplicativoWeb}`, {
        cnpj: cnpj,
        usuario: usuario,
        mensagem: mensagem
      });
      alert('E-mail enviado com sucesso!');
      setOpen(false);
    } catch (error) {
      console.log('Erro ao enviar o e-mail', error, mensagem);
      alert('Erro ao enviar o e-mail. Consulte o suporte técnico.');
    } finally {
      setCarregando(false);
    }
  };

  return (
    <div className="text-center mt-2" >
      <button
        className="text-xs text-gray-50 mt-2 font-semibold w-2/3 py-2 px-4 border border-teal-400 hover:border-transparent rounded" style={{backgroundColor: '#00C7D4'}}
        onClick={abrirCaixaDeSugestoes}
      >
        Deixe sua sugestão!
      </button>
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <LightBulbIcon className="h-6 w-6 text-teal-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Ajude-nos a melhorar nosso aplicativo
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                          Queremos tornar sua experiência ainda melhor!  Envie suas sugestões de melhorias para o nosso app.  <br/> 
                          </p>
                          <textarea name="mensagem" type="text" value={mensagem} onChange={handleChange} className="block w-full rounded-lg py-1.5 px-3 text-sm/6" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto disabled:bg-opacity-20"
                      onClick={() => enviarMensagem()}
                      disabled={carregando|| mensagem.trim() === ''}
                      >
                      {carregando ? (
                        <ClipLoader color="#ffffff" size={15} />
                      ) : (
                        'Enviar'
                      )}
                    </button>
                      
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      data-autofocus
                    >
                      Cancelar
                    </button>

                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default CaixaDeSugestoes