import React, { useContext, useEffect, useState } from 'react'
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import FiltrosContext from '../context/FiltrosController'
import moment from 'moment';

function DataSinc() {

  const { auth } = useAuth();
  const [dataPeriodo, setDataPeriodo] = useState(null);
  const { identificadorAplicativoWeb } = auth;
  const { filtros } = useContext(FiltrosContext);
  const { filial } = filtros;

  useEffect(() => {
    if (filial) {
      axios.post(`/valores/dataSincronizacao/${identificadorAplicativoWeb}`, {
        filial: `${filial}`,

      })
        .then((response) => {
          setDataPeriodo(response.data);
        })
        .catch((err) => {
          console.error("Ops! Ocorreu um erro ao carregar as informações" + err);
        });
    }
  }, [filial]);

  let msgDataInicial = "";
  let msgDataFinal = "";

  if (dataPeriodo?.dataSincInicial === "" || dataPeriodo?.dataSincInicial < "2000-01-01") {
    msgDataInicial = "";
  } else {
    msgDataInicial = "Informações disponíveis desde " + moment(dataPeriodo?.dataSincInicial).format('DD/MM/YYYY');
  }

  if (dataPeriodo?.dataSincFinal === "" || dataPeriodo?.dataSincFinal < "2000-01-01") {
    msgDataFinal = "";
  } else {
    msgDataFinal = "Última atualização às " + moment(dataPeriodo?.dataSincFinal).format('DD/MM/YYYY HH:mm');
  }

  return (
    <div>
      <p style={{ textAlign: 'center', fontFamily: 'Inter', color: '#9CA3AF', fontSize: '12px', margin: '0.5rem 0 0 0.5rem' }}> {msgDataFinal} </p>
      <p style={{ textAlign: 'center', fontFamily: 'Inter', color: '#9CA3AF', fontSize: '12px', margin: '0 0 0 0.5rem' }}> {msgDataInicial} </p>
    </div>


  )
}

export default DataSinc;