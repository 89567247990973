import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)

    const logout = (e) => {
        e?.preventDefault();
        setAuth({});
        navigate('/');
    }

    useEffect(() => {
        if (!loading) {
            localStorage.setItem('login', JSON.stringify(auth));            
        }
    }, [auth,loading])


    useEffect(() => {
        const authLogin = localStorage.getItem('login');
        setAuth(JSON.parse(authLogin))
        setLoading(false)        
    }, [])


    return (
        <AuthContext.Provider value={{ auth, setAuth, logout }}>        
            {!loading && children}
        </AuthContext.Provider>
    )
}

export default AuthContext;