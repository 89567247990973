import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthProvider } from './context/AuthProvider';
import { FiltrosProvider } from './context/FiltrosController';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import "./assets/scss/argon-dashboard-react.scss";
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <FiltrosProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </FiltrosProvider>
    </AuthProvider>
  </BrowserRouter>
);

// Registra o service worker e lida com a nova versão
serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (registration.waiting) {
      // Se há um SW aguardando para ser ativado, notifica o usuário
      notifyUserAboutUpdate(registration);
    }
  }
});

// Função para notificar o usuário e atualizar o SW
function notifyUserAboutUpdate(registration) {
  const updateNotification = window.alert(
    "Nova versão atualizada com sucesso! "
  );

  if (updateNotification) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  }
}

// Verifica se o usuário já deu permissão para notificações
if (Notification.permission === 'default') {
  Notification.requestPermission();
}

// Se você quiser medir o desempenho da sua aplicação, pode passar uma função para logar os resultados (por exemplo: reportWebVitals(console.log))
// ou enviar para um endpoint de analytics. Saiba mais: https://bit.ly/CRA-vitals
reportWebVitals();
