import { Button } from "reactstrap";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import DataSinc from "./DataSinc";
import NavBar from "./NavBar";

const Layout = () => {
    const { logout } = useContext(AuthContext);
    const { auth } = useAuth();

    return (
        <>
            {auth?.usuario && <NavBar />}

            <div className={!auth?.usuario ? "App" : ""}>
                {auth?.usuario &&
                    <div className="shadow"
                        style={{
                            border: 'none',
                            color: "white",
                            padding: 20,
                            width: '100%',
                            height: '250px',
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            marginTop: '-50px !important',
                            backgroundColor: '#00C7D4'
                        }}
                    >
                    </div>
                }

                <Outlet />

                {auth?.usuario &&
                    <div className="footer">
                        
                       
                            <div
                                style={{
                                    backgroundColor: 'rgb(235, 235, 235)',
                                    width: '100%',
                                    height: '40px',
                                    marginBottom: '-50px !important',
                                    borderTopLeftRadius: 15,
                                    borderTopRightRadius: 15,
                                    marginTop: '10px',
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                    
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: "#8898AA",
                                        fontFamily: 'Roboto',
                                        fontWeight: 400,
                                        display: 'flex',
                                        fontSize: 14,
                                        flexDirection: 'column',
                                    }}
                                    onClick={logout}
                                >
                                    Sair
                                </Button>
                            </div>
                        </div>
                   
                }
            </div>
        </>
    );
}

export default Layout;
