import React, { useEffect } from 'react';
import Login from './components/Login';
import Home from './components/Home';
import Install from './components/Install';
import Layout from './components/Layout';
import Missing from './components/Missing';
import RequireAuth from './components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGa from 'react-ga'



function App() {

useEffect(() => {
  ReactGa.initialize('G-MT4B6KENYS')

  ReactGa.pageview('/')
}, [])


  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="install" element={<Install />} />
        <Route element={<RequireAuth />}>s
          <Route path="/" element={<Home />} />
        </Route>

        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;

